import React, { Component, useState } from 'react';
import Navbar from './shared/Navbar';

import { db } from "../firebase-config";
import {
    collection,
    addDoc,
} from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';

function Contact(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");

    const interestedDoc = collection(db, "interested");

    const createInterested = async () => {
        if (email !== '') {
            await addDoc(interestedDoc, { email: email, name: name, message: message, createdAt: new Date() });
            toast.success('Success!')

            setEmail('');
            setName('');
            setMessage('');
        } else {
            toast.error('You need to enter your email!')
        }
    };

    return (
        <div>
            <Navbar dark={true} />
            <section id="contacts-2" class="bg-snow wide-50 inner-page-hero contacts-section division">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10 col-lg-8">
                            <div class="section-title title-02 mb-80">
                                <h2 class="h2-xs">Have a question? Need help? Don't hesitate, drop us a line</h2>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-xl-8">
                            <div class="form-holder">
                                <div class="col-md-12">
                                    <p class="p-lg">Your Name: </p>
                                    <input type="text" name="name" value={name} class="form-control name" placeholder="Your Name" onChange={(event) => {
                                        setName(event.target.value);
                                    }} />
                                </div> <br />
                                <div class="col-md-12">
                                    <p class="p-lg">Your Email Address: </p>
                                    <input type="text" name="email" value={email} class="form-control email" placeholder="Email Address*" onChange={(event) => {
                                        setEmail(event.target.value);
                                    }} />
                                </div> <br />
                                <div class="col-md-12">
                                    <p class="p-lg">Message: </p>
                                    <textarea class="form-control message" name="message" value={message} rows="6" placeholder="When is Brand Bounce launching?" onChange={(event) => {
                                        setMessage(event.target.value);
                                    }}></textarea>
                                </div>
                                <div class="col-md-12 mt-15 form-btn text-right">
                                    <button type="submit" class="btn btn-orange-red tra-grey-hover submit" onClick={createInterested}>Send Message</button>
                                </div>
                                <Toaster
                                    position="bottom-right"
                                    reverseOrder={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Contact;