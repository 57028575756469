import React, { useState } from 'react';
import Header from './Header';
import { db } from "../firebase-config";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';
import FeatureBox from './shared/FeatureBox';

function Home() {

  const [email, setEmail] = useState("");

  const interestedDoc = collection(db, "interested");

  const createInterested = async () => {
    if (email !== '') {
      await addDoc(interestedDoc, { email: email, createdAt: new Date() });
      toast.success('Success!')
  
      setEmail('');
    } else {
      toast.error('You need to enter your email!')
    }
  };

  return (
    <div className='home-main'>
      <Header />
      <section id="features-2" class="wide-60 features-section division">
        <div class="container">
          <div class="fbox-2-wrapper text-center">
            <div class="row row-cols-1 row-cols-md-3">
              <div class="col">
                <div class="fbox-2 mb-40 wow fadeInUp">
                  <div class="fbox-ico-center ico-65 shape-ico orange-red-color">
                    <img class="ico-bkg" src="images/ico-bkg.png" alt="ico-bkg" />
                    <span class="flaticon-double-click"></span>
                  </div>
                  <div class="fbox-txt-center">
                    <h5 class="h5-md">What's the outcome?</h5>
                    <p class="p-lg">Utilize AI to dramatically enhance your e-commerce sales and conversions. Our AI-driven content creation tools transform your creatives into high-conversion entities.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="fbox-2 mb-40 wow fadeInUp">
                  <div class="fbox-ico-center ico-65 shape-ico orange-red-color">
                    <img class="ico-bkg" src="images/ico-bkg.png" alt="ico-bkg" />
                    <span class="flaticon-web-browser"></span>
                  </div>
                  <div class="fbox-txt-center">
                    <h5 class="h5-md">How will I achieve this?</h5>
                    <p class="p-lg">With our expertly designed tools, confidently elevate your online sales and engagement, realizing your e-commerce goals with proven effectiveness.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="fbox-2 mb-40 wow fadeInUp">
                  <div class="fbox-ico-center ico-65 shape-ico orange-red-color">
                    <img class="ico-bkg" src="images/ico-bkg.png" alt="ico-bkg" />
                    <span class="flaticon-web-programming"></span>
                  </div>
                  <div class="fbox-txt-center">
                    <h5 class="h5-md">How fast?</h5>
                    <p class="p-lg">Start seeing tangible results in as little as two days. Immediate access upon sign-up means no downtime—just straight to business growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content-2a" class="content-2 bg-04 wide-60 content-section division">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-5 col-lg-6">
              <div class="rel img-block left-column wow fadeInRight">
                <img class="img-fluid" src="images/img-03.png" alt="content-image" />
              </div>
            </div>
            <div class="col-md-7 col-lg-6">
              <div class="txt-block right-column white-color wow fadeInLeft">
                <h2 class="h2-xs">Exclusive Pre-Launch Offer</h2>
                <ul class="simple-list">
                  <li class="list-item">
                    <p class="p-lg">7-Day Free Trial: Explore the full capabilities of our AI-driven platform with zero commitment.
                    </p>
                  </li>
                  <li class="list-item">
                    <p class="p-lg">20% Off for Lifetime: Secure this exclusive discount just by registering your email address below.</p>
                  </li>
                  <li class="list-item">
                    <p class="p-lg">Satisfaction Guaranteed: If our platform doesn't meet your expectations, cancel within the first month for a full refund.
                    </p>
                  </li>
                </ul>

              </div>
              <div name="quickform" class="quick-form shadow-form">
                <div class="input-group">
                  <input type="email" name="email" value={email} class="form-control email" placeholder="Your email address" autocomplete="off" required onChange={(event) => {
                    setEmail(event.target.value);
                  }} />
                  <span class="input-group-btn form-btn">
                    <button type="submit" class="btn btn-orange-red tra-white-hover mr-15 submit" onClick={createInterested}>Join</button>
                  </span>
                  <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features-4" class="wide-60 features-section division">
        <div class="container">

          <div class="row justify-content-center">
            <div class="col-lg-10 col-xl-8">
              <div class="section-title title-01 mb-70">
                <h2 class="h2-md">We’re Better. Here’s Why…</h2>
                <p class="p-xl">Unlock the full potential of your eCommerce store with Brand Bounce, the premier SaaS platform designed specifically for boosting your online sales.
                </p>
              </div>
            </div>
          </div>


          <div class="fbox-4-wrapper">
            <div class="row row-cols-1 row-cols-md-2">

              <FeatureBox title="Automated Content Generation" text="Effortlessly produce high-quality, SEO-optimized marketing copy tailored to your products and target audience. Utilize AI-driven technology to generate blogs, product descriptions, and promotional materials that resonate with your customers." icon="flaticon-double-click" />
              <FeatureBox title="Campaign Management Tools" text="Plan, execute, and monitor marketing campaigns from a single dashboard. Automate scheduling, track performance, and adjust strategies in real-time to maximize engagement and conversions." icon="flaticon-folder-3" />
              <FeatureBox title="Customizable Content Templates" text="Access a wide range of professionally designed templates for ads, emails, and social media posts. Customize these templates to maintain brand consistency and accelerate content creation." icon="flaticon-puzzle" />
              <FeatureBox title="Enhanced Security Protocols" text="Protect your data with top-tier security measures including encryption, secure data storage, and compliance with industry standards, ensuring your marketing operations are safeguarded against threats." icon="flaticon-shield" />
              <FeatureBox title="AI-Powered Marketing Engine" text="Leverage our powerful AI engine for predictive analytics, customer behavior analysis, and automated decision-making, helping you anticipate market trends and customer needs effectively." icon="flaticon-server-1" />
              <FeatureBox title="Collaborative Workspace" text="Facilitate teamwork with features that allow multiple team members to collaborate on projects in real-time. Share feedback, edits, and approvals efficiently within a unified platform." icon="flaticon-share" />

            </div>
          </div>


        </div>
      </section>

      <section id="faqs-2" class="wide-60 faqs-section division">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 col-xl-8">
              <div class="section-title title-01 mb-80">
                <h2 class="h2-md">Got Questions? Look Here</h2>
              </div>
            </div>
          </div>
          <div class="faqs-2-questions">
            <div class="row row-cols-1 row-cols-lg-2">
              <div class="col">
                <div class="questions-holder pr-15">
                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">What makes your platform unique?</h5>
                    <p class="p-lg">Our platform utilizes advanced AI technology to streamline the content creation process, offering tools that optimize SEO, enhance user engagement, and ensure compliance with advertising standards—all designed to boost your e-commerce sales efficiently.
                    </p>
                  </div>
                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">How does the AI enhance content creation?	</h5>
                    <p class="p-lg">Our AI analyzes current market trends and consumer behavior to suggest the most effective content strategies. It automates tedious tasks like keyword integration and compliance checks, allowing you to focus on creative aspects.
                    </p>
                  </div>
                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">Do you have a trial period?</h5>
                    <p class="p-lg">Yes, we offer a 7-day free trial. You can explore all the features of our platform during this period without any commitment. If you're not satisfied, you can cancel anytime within the trial for a full refund.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="questions-holder pl-15">
                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">How quickly will I see results from using your platform?</h5>
                    <p class="p-lg">As soon as you complete the onboarding process, your content will be ready to go. Most users have their optimized content prepared and published within just two days, thanks to our AI-driven tools that streamline the entire creation and optimization process.
                    </p>
                  </div>
                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">When will it launch?</h5>
                    <p class="p-lg">Our platform is set to launch in Q3 2024. Sign up now to get early access and receive updates as we approach the launch.
                    </p>
                  </div>

                  <div class="question wow fadeInUp">
                    <h5 class="h5-md">How much will it cost?</h5>
                    <p class="p-lg">The cost of our platform depends on your specific business needs. For a tailored plan that fits your requirements, please contact us at office@brand-bounce.com. We're here to provide you with a solution that maximizes your value.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="more-questions">
                <h5 class="h5-sm">Have more questions? <a href="/contact">Ask your question here</a></h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
