import React from 'react';
import Navbar from '../shared/Navbar';

function Terms() {

    return (
        <div>
            <Navbar dark={true} />
            <section id="terms-page" class="bg-snow wide-70 inner-page-hero terms-section division">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="terms-title text-center">
                                <h2 class="h2-md">Terms of Service</h2>
                                <p class="p-xl grey-color">Effective Date: May 13, 2024</p>

                            </div>
                            <hr class="divider" />


                            <div class="terms-box">
                                <h5 class="h5-xl">1. Agreement to Terms</h5>

                                <p class="p-lg">
                                    1.1 You agree that by accessing the site, www.brand-bounce.com, and/or services, you have read, understood, and agree to be bound by all of these Terms of Agreement. If you do not agree with all of these Terms of Agreement, then you are expressly prohibited from using the site and you must discontinue use immediately.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">2. Intellectual Property Rights</h5>

                                <p class="p-lg">
                                    2.1 Unless otherwise indicated, the site and all content and other materials therein, including, without limitation, the Brand Bounce logo, and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof are the proprietary property of Brand Bounce or our affiliates, licensors, or users as applicable.
                                </p>

                                <p class="p-lg">
                                    2.2 No part of the site, nor any content, may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">3. User Representations</h5>

                                <p class="p-lg">
                                    3.1 By using the Site, you represent and warrant that:
                                </p>

                                <ul class="simple-list">

                                    <li class="list-item">
                                        <p class="p-lg">                                    you have the legal capacity and you agree to comply with these Terms of Agreement;

                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">                                    you are not a minor in the jurisdiction in which you reside;

                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">                                    you will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;

                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">                                    you will not use the Site for any illegal or unauthorized purpose;

                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">                                    your use of the Site will not violate any applicable law or regulation.

                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">4. User Registration</h5>

                                <p class="p-lg">
                                    4.1 You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">5. Prohibited Activities</h5>

                                <p class="p-lg">
                                    5.1 You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">6. Modifications and Interruptions</h5>

                                <p class="p-lg">
                                    6.1 We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.
                                </p>
                            </div>


                            <div class="terms-box">
                                <h5 class="h5-xl">7. User Data Rights Under GDPR</h5>

                                <p class="p-lg">
                                    7.1 As a user within the European Union, you are entitled under the General Data Protection Regulation to request access to, rectification, or erasure of your personal data, or restriction of processing concerning you, or to object to processing as well as the right to data portability.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">8. Governing Law</h5>

                                <p class="p-lg">
                                    8.1 These Terms shall be governed by and defined following the laws of Romania. Brand Bounce and yourself irrevocably consent that the courts of Romania shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">9. Dispute Resolution</h5>

                                <p class="p-lg">
                                    9.1 Any legal action of whatever nature brought by either you or us (collectively, the "Parties" and individually, a "Party") shall be commenced or prosecuted in the state and federal courts located in Romania, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such courts.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">10. Contact Us</h5>

                                <p class="p-lg">
                                    10.1 To resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at: office@brand-bounce.com.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Terms;