import React from 'react';
import Navbar from '../shared/Navbar';

function Privacy() {

    return (
        <div>
            <Navbar dark={true} />
            <section id="terms-page" class="bg-snow wide-70 inner-page-hero terms-section division">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="terms-title text-center">
                                <h2 class="h2-md">Privacy Policy</h2>
                                <p class="p-xl grey-color">Effective Date: May 13, 2024</p>

                            </div>
                            <hr class="divider" />
                            <div class="terms-box mt-60">
                                <p class="p-lg">Brand Bounce ("we", "us", or "our") respects the privacy of our users ("user" or "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.brand-bounce.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site").
                                </p>
                                <p class="p-lg">Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
                                </p>

                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">Information We Collect</h5>

                                <p class="p-lg">We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>

                                <p class="p-lg">
                                    Personal Data
                                </p>
                                <p class="p-lg">
                                    Personal identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards.
                                </p>
                                <p class="p-lg">
                                    Derivative Data
                                </p>
                                <p class="p-lg">
                                    Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">Use of Your Information</h5>

                                <p class="p-lg">Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:</p>

                                <ul class="simple-list">

                                    <li class="list-item">
                                        <p class="p-lg">Create and manage your account.
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Email you regarding your account or order.
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Increase the efficiency and operation of the Site.
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Monitor and analyze usage and trends to improve your experience with the Site.
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">Disclosure of Your Information</h5>

                                <p class="p-lg">We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>

                                <ul class="simple-list">

                                    <li class="list-item">
                                        <p class="p-lg">By Law or to Protect Rights
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Third-Party Service Providers
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Marketing Communications
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Interactions with Other Users
                                        </p>
                                    </li>

                                    <li class="list-item">
                                        <p class="p-lg">Online Postings
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">Security of Your Information</h5>

                                <p class="p-lg">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">Options Regarding Your Information</h5>

                                <p class="p-lg">
                                You may at any time review or change the information in your account or terminate your account by
                                contacting us using the contact information provided below.
                                </p>
                            </div>

                            <div class="terms-box">
                                <h5 class="h5-xl">GDPR Compliance</h5>

                                <p class="p-lg">
                                We comply with the General Data Protection Regulation (GDPR) and ensure all data processing activities adhere to the principles of data protection such as data minimization, purpose limitation, accuracy, storage limitation, integrity, and confidentiality.
                                </p>
                            </div>


                            <div class="terms-box">
                                <h5 class="h5-xl">Contact Us</h5>

                                <p class="p-lg">
                                If you have questions or comments about this Privacy Policy, please contact us at: office@brand-bounce.com.
                                </p>
                            </div>




                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Privacy;