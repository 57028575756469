import React from "react";

function FeatureBox(props) {
    
    return (
        <div class="col">
            <div class="fbox-4 pc-25 mb-40 wow fadeInUp">

                <div class="fbox-ico">
                    <div class="ico-60 shape-ico orange-red-color">
                        <img class="ico-bkg" src="images/ico-bkg.png" alt="ico-bkg" />
                            <span class={props.icon}></span>
                    </div>
                </div>

                <div class="fbox-txt">

                    <h5 class="h5-md">{props.title}</h5>

                    <p class="p-lg">{props.text}
                    </p>

                </div>

            </div>
        </div>
    );
};

export default FeatureBox;
