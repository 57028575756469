import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Footer from './components/shared/Footer';
import Terms from './components/legal/Terms';
import Privacy from './components/legal/Privacy';
import Cookie from './components/legal/Cookie';

class App extends Component {

  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    );
  }
}

export default App;