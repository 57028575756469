import React, { useState } from "react";
import { db } from "../../firebase-config";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';

function Footer() {
  const [email, setEmail] = useState("");

  const interestedDoc = collection(db, "interested");

  const createInterested = async () => {
    if (email !== '') {
      await addDoc(interestedDoc, { email: email, createdAt: new Date() });
      toast.success('Success!')
  
      setEmail('');
    } else {
      toast.error('You need to enter your email!')
    }
  };

  return (
    <div>
      <footer id="footer-1" class="footer division">
        <div class="container">
          <div class="bottom-footer">
            <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">
              <div class="col">
                <div class="footer-copyright">
                  <p>Harness the full potential of your e-commerce store with Brand Bounce. Where achieving your sales vision quickly and effortlessly becomes reality!</p>
                </div> <br />
              </div>

              <div name="quickform" class="quick-form shadow-form">

                <div class="input-group">
                  <input type="email" name="email" value={email} class="form-control email" placeholder="Your email address" autocomplete="off" required onChange={(event) => {
                    setEmail(event.target.value);
                  }} />
                  <span class="input-group-btn form-btn">
                    <button type="submit" class="btn btn-orange-red tra-white-hover mr-15 submit" onClick={createInterested}>Join</button>
                  </span>
                  <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="bottom-footer">
            <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">


              <div class="col">
                <div class="footer-copyright">
                  <p>© 2024 Brand Bounce All Rights Reserved</p>
                </div>
              </div>


              <div class="col">
                <ul class="bottom-footer-list text-secondary text-end">
                  <li class="first-li"><p><a href="/privacy">Privacy Policy</a></p></li>
                  <li><p><a href="/terms">Terms</a></p></li>
                  <li><p><a href="/cookie">Cookie</a></p></li>
                  <li><p><a href="https://www.facebook.com/profile.php?id=61559754806993" target="_blank">Facebook</a></p></li>
                  <li class="last-li"><p><a href="https://www.instagram.com/usebrandbounce" target="_blank">Instagram</a></p></li>
                </ul>
              </div>


            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
