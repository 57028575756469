import React, { useState, useEffect } from 'react';

const Navbar = ({ dark }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '62px'
  };

  const logoStyle = {
    maxWidth: '150px',
    paddingLeft: "12px",
  };

  const menuStyle = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    color: 'black',
    fontWeight: '400',
    width: '25%'
  };

  const smllogoStyle = {
    margin: 0
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header id="header" class={"header tra-menu " + (isMobile || dark ? "navbar-dark" : "navbar-light")}>
      <div class="header-wrapper">
        {isMobile && <div className="wsmobileheader clearfix" style={headerStyle}>
          <span className="smllogo" style={smllogoStyle}>
            <a href="/" className="logo-black">
              <img src="logo-black.png" alt="header-logo" style={logoStyle} />
            </a>
          </span>
          <ul className="wsmenu-list nav-orange-red-hover" style={menuStyle}>
            <li className="nl-simple" aria-haspopup="true" style={{ textAlign: 'right' }}>
              <a href="/contact" style={{ color: 'black' }}>Contact</a>
            </li>
          </ul>
        </div> }
        <div class="wsmainfull menu clearfix">
          <div class="wsmainwp clearfix">
            <div class="desktoplogo"><a href="/" class="logo-black"><img src="logo-black.png" alt="header-logo" /></a></div>
            <div class="desktoplogo"><a href="/" class="logo-white"><img src="logo.png" alt="header-logo" /></a></div>
            <nav class="wsmenu clearfix">
              <ul class="wsmenu-list nav-orange-red-hover">
                <li class="nl-simple" aria-haspopup="true"><a href="/">Home</a></li>

                <li class="nl-simple" aria-haspopup="true"><a href="/contact">Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
