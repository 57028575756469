import React, { useState } from 'react';
import Navbar from './shared/Navbar';

import { db } from "../firebase-config";
import {
  collection,
  addDoc
} from "firebase/firestore";
import toast, { Toaster } from 'react-hot-toast';


function Header() {
  const [email, setEmail] = useState("");

  const interestedDoc = collection(db, "interested");

  const createInterested = async () => {
    if (email !== '') {
      await addDoc(interestedDoc, { email: email, createdAt: new Date() });
      toast.success('Success!')
  
      setEmail('');
    } else {
      toast.error('You need to enter your email!')
    }
    
  };

  return (
    <div>
      <Navbar />
      <section id="hero-1" class="bg-scroll hero-section division">
        <div class="container">
          <div class="row d-flex align-items-center">
            <div class="col-md-7 col-lg-6">
              <div class="hero-1-txt white-color wow fadeInRight">
                <h2 class="h2-md">AI Marketing content creation software for ecommerce stores.s</h2>
                <p class="p-xl">
                  Achieve your dream sales figures with certainty, speed and simplicity with our AI-Driven platform.
                </p>
                <p class="p-xl">
                  Generate more leads with minimal effort. Our intuitive design tools, automated SEO enhancements, and pre-built templates make content creation effortless and enjoyable.</p>
                <div name="quickform" class="quick-form shadow-form">

                  <div class="input-group">
                    <input type="email" name="email" value={email} class="form-control email" placeholder="Your email address" autocomplete="off" onChange={(event) => {
                      setEmail(event.target.value);
                    }} required />
                    <span class="input-group-btn form-btn">
                      <button type="submit" class="btn btn-orange-red tra-white-hover mr-15 submit" onClick={createInterested}>Join Waitlist</button>
                    </span>
                    <Toaster
                      position="bottom-right"
                      reverseOrder={false}
                    />
                  </div>
                </div>
                <ul class="advantages">
                  <li class="last-li"><p>Limited number of spots available.</p></li>
                </ul>
              </div>
            </div>
            <div class="col-md-5 col-lg-6">
              <div class="hero-1-img wow fadeInLeft">
                <img class="img-fluid" src="images/hero-1-img.png" alt="hero-image" />
              </div>
            </div>
          </div>
        </div>
        <div class="wave-shape-bottom">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 130"><path fill="#ffffff" fill-opacity="1" d="M0,64L120,80C240,96,480,128,720,128C960,128,1200,96,1320,80L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path></svg>
        </div>
      </section>
    </div>

  );
};

export default Header;
